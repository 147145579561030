export const stripeAppearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#e55a28",
    borderRadius: "7px",
  },
  rules: {
    ".Input": {
      padding: "1rem 1.5rem",
      border: "0.5px solid hsl(215 27% 16%)",
    },
    ".Label": {
      padding: "0.5rem 0.25rem",
      fontWeight: "600",
    },
  },
};
