import { InformationCircleIcon } from "@heroicons/react/24/outline";

export const SectionInfo = (props) => {
  return (
    <div className="pt-4 pb-6 flex flex-col gap-2 text-sm border-b-2">
      <h3 className="flex flex-row items-center">
        <InformationCircleIcon className="inline-block w-5 h-5 mr-1" />
        <span className="font-bold">Information</span>
      </h3>
      <div className="ml-6 flex flex-col gap-1">{props.children}</div>
    </div>
  );
};
