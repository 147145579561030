import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import clsx from "clsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SectionInfo } from "../library/sections/Information";
import { StudentSelector } from "../library/selector/Student";
import { TermSelector } from "../library/selector/Term";
import { ClassSelector } from "../library/selector/Class";
import { VALIDATE_VOUCHER, DRAFT_ENTITY } from "../utils/mutations";
import { formatter } from "../utils/helpers";

export default function Enrolment(props) {
  const [student, setStudent] = useState(null);
  const [term, setTerm] = useState(null);
  const [classInfo, setClassInfo] = useState(null);
  const [enableVoucher, setEnableVoucher] = useState(false);
  const [vouchers, setVouchers] = useState({
    activeKids: null,
    creativeKids: null,
  });
  const [paymentActive, setPaymentActive] = useState(false);

  const getGroupIds = (groups) => groups.map((group) => group.id);

  const clear = () => {
    setStudent(null);
    setTerm(null);
    setClassInfo(null);
    setVouchers({
      activeKids: null,
      creativeKids: null,
    });
  };

  useEffect(() => {
    if (student) {
      setClassInfo(null);
      setVouchers({
        activeKids: null,
        creativeKids: null,
      });
    }
  }, [student]);

  useEffect(() => {
    if (term) {
      setClassInfo(null);
      setVouchers({
        activeKids: null,
        creativeKids: null,
      });
    }
  }, [term]);

  useEffect(() => {
    if (student && classInfo) {
      if (student.age >= 5 && student.age <= 18) {
        setEnableVoucher(true);
      } else {
        setEnableVoucher(false);
      }
    }
  }, [classInfo, student]);

  return (
    <UserLayout title="Class Enrolment | Swastik App">
      <section>
        {/* heading */}
        <SectionHead heading="Class Enrolment"></SectionHead>
        {/* information */}
        <SectionInfo>
          <p>
            <strong>Enrolment:</strong> can be used to enrol for all remaining
            class sessions.
          </p>
          <p>
            <i>
              Please note that all enrolments are final and non-transferable.
            </i>
          </p>
        </SectionInfo>
        {/* student */}
        <StudentSelector
          selection={student}
          setSelection={setStudent}
          type="enrolment"
        />
        {/* term */}
        {student && (
          <TermSelector
            selection={term}
            setSelection={setTerm}
            type="enrolment"
          />
        )}
        {/* classes */}
        {student && term && (
          <ClassSelector
            groupsId={getGroupIds(student.groups)}
            student={student}
            term={term}
            setTerm={setTerm}
            selection={classInfo}
            setSelection={setClassInfo}
            type="enrolment"
          />
        )}
        {classInfo && enableVoucher && (
          <AddVoucherForm
            vouchers={vouchers}
            setVouchers={setVouchers}
            classInfo={classInfo}
            paymentActive={paymentActive}
          />
        )}
        {/* add enrolment form */}
        {student && term && classInfo && (
          <AddEnrolmentForm
            student={student}
            term={term}
            classInfo={classInfo}
            vouchers={vouchers}
            setPaymentActive={setPaymentActive}
            clear={clear}
          />
        )}
      </section>
    </UserLayout>
  );
}

function AddVoucherForm(props) {
  const { vouchers, setVouchers, classInfo, paymentActive } = props;

  const [classTotal, setClassTotal] = useState(0);

  const {
    register: activeRegister,
    handleSubmit: activeHandleSubmit,
    reset: activeReset,
    getValues: activeGetValues,
    watch: activeWatch,
    formState: { errors: activeErrors },
  } = useForm({ mode: "onChange" });

  const {
    register: creativeRegister,
    handleSubmit: creativeHandleSubmit,
    reset: creativeReset,
    getValues: creativeGetValues,
    watch: creativeWatch,
    formState: { errors: creativeErrors },
  } = useForm({ mode: "onChange" });

  const notifyUsed = () => toast.error("Voucher already used!");

  const activeCode = activeWatch("activeKids");
  const creativeCode = creativeWatch("creativeKids");

  const setVoucherWithAmount = (key, code = null) => {
    if (code === null) {
      setVouchers({
        ...vouchers,
        [key]: null,
      });
    } else {
      const activeVoucherAmt = vouchers.activeKids ? 5000 : 0;
      const creativeVoucherAmt = vouchers.creativeKids ? 5000 : 0;
      const totalVoucherAmt = activeVoucherAmt + creativeVoucherAmt;

      const calcTotal = classTotal - totalVoucherAmt - 5000;

      let chargeAmt = 0;

      if (calcTotal > 5000) {
        chargeAmt = 5000;
      } else if (calcTotal > -5000 && calcTotal <= 5000) {
        chargeAmt = 5000 + calcTotal;
      } else {
        chargeAmt = 0;
      }

      let type;
      if (key === "activeKids") type = "ACTIVE_KIDS_NSW";
      if (key === "creativeKids") type = "CREATIVE_KIDS_NSW";

      setVouchers({
        ...vouchers,
        [key]: `${code.toUpperCase()}::${chargeAmt}::${type}`,
      });
    }
  };

  const resetAllVouchers = () => {
    setVouchers({
      activeKids: null,
      creativeKids: null,
    });
    creativeReset({
      creativeKids: "",
    });
    activeReset({
      activeKids: "",
    });
  };

  const [validateActiveKidsVoucher, { loading: activeLoading }] = useMutation(
    VALIDATE_VOUCHER,
    {
      onCompleted: (data) => {
        if (data.valid) {
          setVoucherWithAmount("activeKids", activeCode.toUpperCase());
        } else {
          notifyUsed();
        }
      },
      onError: (error) => {
        toast.error("Something went wrong, please try again later!");
      },
    }
  );

  const [validateCreativeKidsVoucher, { loading: creativeLoading }] =
    useMutation(VALIDATE_VOUCHER, {
      onCompleted: (data) => {
        if (data.valid) {
          setVoucherWithAmount("creativeKids", creativeCode.toUpperCase());
        } else {
          notifyUsed();
        }
      },
      onError: (error) => {
        toast.error("Something went wrong, please try again later!");
      },
    });

  const onActiveSubmit = (data) => {
    validateActiveKidsVoucher({
      variables: {
        type: "ACTIVE_KIDS_NSW",
        code: data.activeKids.toUpperCase(),
      },
    });
  };

  const onCreativeSubmit = (data) => {
    validateCreativeKidsVoucher({
      variables: {
        type: "CREATIVE_KIDS_NSW",
        code: data.creativeKids.toUpperCase(),
      },
    });
  };

  useEffect(() => {
    setClassTotal(classInfo.sessionsAvailable * classInfo.price);
    setVouchers({
      activeKids: null,
      creativeKids: null,
    });
    creativeReset({
      creativeKids: "",
    });
    activeReset({
      activeKids: "",
    });
  }, [classInfo, setVouchers, creativeReset, activeReset]);

  return (
    <div className="pb-6">
      <div className="flex flex-col md:flex-row gap-6">
        {/* active kids voucher form */}
        <form
          onSubmit={activeHandleSubmit(onActiveSubmit)}
          className="md:basis-1/2"
        >
          {/* input with button */}
          <div className="flex flex-row items-end gap-2">
            <div className="relative flex flex-col basis-3/4">
              <label className="label" htmlFor="activekids-code">
                <span className="label-text font-semibold">
                  Active Kids Voucher
                </span>
              </label>
              <input
                id="activekids-code"
                type="text"
                className="input input-bordered bg-white uppercase"
                placeholder="Enter voucher code"
                {...activeRegister("activeKids", {
                  required: "Please enter a valid voucher code",
                  pattern: {
                    value: /^5\w{15}$/g,
                    message: "Please enter a voucher code",
                  },
                  minLength: {
                    value: 16,
                    message: "Please enter a valid voucher code",
                  },
                  maxLength: {
                    value: 16,
                    message: "Please enter a valid voucher code",
                  },
                  // validate that it should not be same as creative kids voucher
                  validate: {
                    notSame: (value) =>
                      value !== creativeGetValues("creativeKids") ||
                      "This voucher is already used",
                  },
                })}
                disabled={vouchers.activeKids || paymentActive ? true : false}
              />
              {activeErrors.activeKids && (
                <div className="absolute -bottom-6 left-2 mt-1 text-red-700">
                  <small>{activeErrors.activeKids.message}</small>
                </div>
              )}
              {activeCode && (
                <input
                  type="reset"
                  value="Remove"
                  alt="Clear the search form"
                  className="absolute top-10 right-0 mt-2 mr-2 btn btn-xs btn-outline btn-error"
                  onClick={() => {
                    resetAllVouchers();
                  }}
                  disabled={paymentActive ? true : false}
                />
              )}
            </div>
            <button
              type="submit"
              className={clsx("basis-1/4 btn", activeLoading && "loading")}
              disabled={vouchers.activeKids || paymentActive ? true : false}
            >
              Use Voucher
            </button>
          </div>
        </form>
        {/* creative kids voucher form */}
        <form
          onSubmit={creativeHandleSubmit(onCreativeSubmit)}
          className="md:basis-1/2"
        >
          {/* input with button */}
          <div className="flex flex-row items-end gap-2">
            <div className="relative flex flex-col basis-3/4">
              <label className="label" htmlFor="creativekids-code">
                <span className="label-text font-semibold">
                  Creative Kids Voucher
                </span>
              </label>
              <input
                id="creativekids-code"
                type="text"
                className="input input-bordered bg-white uppercase"
                placeholder="Enter voucher code"
                {...creativeRegister("creativeKids", {
                  required: "Please enter a valid voucher code",
                  pattern: {
                    value: /^9\w{15}$/g,
                    message: "Please enter a voucher code",
                  },
                  minLength: {
                    value: 16,
                    message: "Please enter a valid voucher code",
                  },
                  maxLength: {
                    value: 16,
                    message: "Please enter a valid voucher code",
                  },
                  // validate that it should not be same as active kids voucher
                  validate: {
                    notSame: (value) =>
                      value !== activeGetValues("activeKids") ||
                      "This voucher is already used",
                  },
                })}
                disabled={vouchers.creativeKids || paymentActive ? true : false}
              />
              {creativeErrors.creativeKids && (
                <div className="absolute -bottom-6 mt-1 text-red-700">
                  <small>{creativeErrors.creativeKids.message}</small>
                </div>
              )}
              {creativeCode && (
                <input
                  type="reset"
                  value="Remove"
                  alt="Clear the search form"
                  className="absolute top-10 right-0 mt-2 mr-2 btn btn-xs btn-outline btn-error"
                  onClick={() => {
                    resetAllVouchers();
                  }}
                  disabled={paymentActive ? true : false}
                />
              )}
            </div>
            <button
              type="submit"
              className={clsx("basis-1/4 btn", creativeLoading && "loading")}
              disabled={vouchers.creativeKids || paymentActive ? true : false}
            >
              Use Voucher
            </button>
          </div>
        </form>
      </div>
      <div className="mt-4">
        <small className="italic p-1 font-semibold">
          Please note that we will manually verify & redeem full voucher value
          for the selected class and remaining value will be forfeited. Please
          ensure you enter correct voucher code, if we are unable to redeem the
          full voucher value, we will contact you to arrange for the remaining
          value to be paid.
        </small>
      </div>
    </div>
  );
}

function AddEnrolmentForm(props) {
  const { student, term, classInfo, vouchers, clear } = props;

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [amount, setAmount] = useState(0);

  const { handleSubmit, reset } = useForm({
    mode: "onChange",
  });

  const [draftEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        clear();
        reset();
        // navigate to the transactions page with the transaction id
        navigate(`/transactions/${data.transaction}`);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
  });

  const onSubmit = () => {
    const input = {
      type: "ENROLMENT",
      enrolment: {
        userId: user.data.id,
        studentId: student.id,
        termId: term.id,
        classId: classInfo.id,
        total: parseInt(amount),
        vouchers: [vouchers.activeKids, vouchers.creativeKids].filter(
          (voucher) => voucher !== null
        ),
      },
    };

    draftEntity({ variables: { ...input } });
  };

  useEffect(() => {
    const activeVoucherAmt = vouchers.activeKids ? 5000 : 0;
    const creativeVoucherAmt = vouchers.creativeKids ? 5000 : 0;
    const totalVoucherAmt = activeVoucherAmt + creativeVoucherAmt;

    const total =
      classInfo.sessionsAvailable * classInfo.price - totalVoucherAmt;

    setAmount(total < 0 ? 0 : total);
  }, [classInfo, vouchers]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary mt-6", loading && "loading")}
        >
          Finalise Enrolment, Pay {formatter.format(amount / 100)}
        </button>
      </form>
      <button
        className="btn btn-outline w-full mt-2"
        onClick={() => {
          reset();
          clear();
        }}
      >
        Cancel
      </button>
    </>
  );
}
