export const UpdateStudentModal = (props) => {
  return (
    <>
      <label
        htmlFor="student-modal-update"
        className={props.btn ? props.btn : "btn modal-button"}
      >
        {props.label}
      </label>

      <input
        type="checkbox"
        id="student-modal-update"
        className="modal-toggle"
      />
      <label
        className="modal modal-bottom sm:modal-middle"
        htmlFor="student-modal-update"
      >
        <label className="modal-box relative" htmlFor="">
          <h3 className="font-bold text-lg">{props.title}</h3>
          <p className="py-4">{props.content}</p>
          <div>{props.children}</div>
        </label>
      </label>
    </>
  );
};
