import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Link, useSearchParams } from "react-router-dom";
import { PublicLayout } from "../layouts/Public";
import { useMutation, gql } from "@apollo/client";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/authContext";
import toast from "react-hot-toast";

function Register(props) {
  const { login } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  // get url search param "to"
  const [searchParams] = useSearchParams();
  const to = searchParams.get("to");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const notifyError = () => toast.error("Unable to create your account!");

  const USER_REGISTER = gql`
    mutation Register($input: registerProfile) {
      auth: register(input: $input) {
        token
        user {
          id
          email
          admin
        }
      }
    }
  `;

  const [registerUser, { loading, error }] = useMutation(USER_REGISTER, {
    update(_, { data: { auth: userData } }) {
      login(userData);
    },
  });

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      dob,
      gender,
      street,
      suburb,
      postcode,
    } = data.register;
    registerUser({
      variables: {
        input: {
          firstName,
          lastName,
          email: email.toLowerCase(),
          phone,
          dob,
          gender,
          street,
          suburb,
          postcode,
        },
      },
    });
  };

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      notifyError();
    }
  }, [error]);

  return (
    <PublicLayout title="Register | Swastik App">
      <div className="text-center pb-4">
        <h1 className="text-4xl font-bold">Register</h1>
        <div className="text-center pt-4">
          <p className="text-gray-600">
            Please provide the information below to create a new account
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
        <div className="flex justify-between space-x-3">
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">First name</span>
            </label>
            <input
              type="text"
              placeholder="John"
              className="input input-bordered w-full"
              {...register("register.firstName")}
              required
            />
          </div>
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Last name</span>
            </label>
            <input
              type="text"
              placeholder="Doe"
              className="input input-bordered w-full"
              {...register("register.lastName")}
              required
            />
          </div>
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Date of birth</span>
          </label>
          <input
            type="date"
            placeholder="Type here"
            className="input input-bordered w-full"
            {...register("register.dob")}
            required
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Gender</span>
          </label>
          <select
            defaultValue="Select"
            className="select select-bordered  w-full"
            {...register("register.gender")}
            required
          >
            <option disabled>Select</option>
            <option value="Female">Female</option>
            <option value="Male">Male</option>
          </select>
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Phone</span>
          </label>
          <input
            type="tel"
            placeholder="0400 000 000"
            className="input input-bordered w-full"
            {...register("register.phone")}
            required
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">Email address</span>
          </label>
          <input
            type="email"
            placeholder="john.doe@email.com"
            className="input input-bordered w-full"
            {...register("register.email")}
            required
          />
        </div>
        <div>
          <label className="label">
            <span className="label-text font-semibold">
              Confirm email address
            </span>
          </label>
          <input
            type="email"
            placeholder="john.doe@email.com"
            className="input input-bordered w-full"
            {...register("register.confirmEmail", {
              validate: (value) => {
                if (value === watch("register.email")) {
                  return true;
                }
                return "Email addresses do not match";
              },
            })}
            required
          />
        </div>
        <ErrorMessage
          errors={errors}
          name="register.confirmEmail"
          render={({ message }) => <p className="pt-2 text-error">{message}</p>}
        />
        <div>
          <label className="label">
            <span className="label-text font-semibold">Street</span>
          </label>
          <input
            type="text"
            placeholder="Street address"
            className="input input-bordered w-full"
            {...register("register.street")}
          />
        </div>
        <div className="flex justify-between space-x-3">
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Suburb</span>
            </label>
            <input
              type="text"
              placeholder="Suburb"
              className="input input-bordered w-full"
              {...register("register.suburb")}
            />
          </div>
          <div className="w-full">
            <label className="label">
              <span className="label-text font-semibold">Postcode</span>
            </label>
            <input
              type="text"
              placeholder="Postcode"
              className="input input-bordered w-full"
              {...register("register.postcode")}
            />
          </div>
        </div>
        <div className="form-control">
          <label className="label cursor-pointer justify-start gap-2">
            <input
              type="checkbox"
              checked
              className="checkbox checkbox-sm"
              {...register("register.terms")}
              required
            />
            <span className="label-text">
              By registering, I agree to the{" "}
              <a
                href="https://swastikdance.com.au/terms/"
                className="font-semibold"
                target="_blank"
                rel="noreferrer"
              >
                terms
              </a>{" "}
              and{" "}
              <a
                href="https://swastikdance.com.au/privacy-policy/"
                className="font-semibold"
                target="_blank"
                rel="noreferrer"
              >
                privacy
              </a>
            </span>
          </label>
        </div>
        <div className="pt-4">
          <button
            type="submit"
            className={
              loader
                ? "btn btn-primary w-full loading"
                : "btn btn-primary w-full"
            }
          >
            Register
          </button>
        </div>
      </form>
      <div className="text-center pt-6">
        <Link
          to={{
            pathname: "/login",
            search: to ? `?to=${to}` : "",
          }}
          className="text-secondary"
        >
          Already have an account?
        </Link>
      </div>
    </PublicLayout>
  );
}

export default Register;
