import { useState } from "react";
import { useQuery } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import toast from "react-hot-toast";
import clsx from "clsx";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { STUDENTS_LIST } from "../../utils/queries";

const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

export const StudentSelector = (props) => {
  const { selection, setSelection, type, label = "Select a student" } = props;

  const [students, setStudents] = useState([]);

  useQuery(STUDENTS_LIST, {
    onCompleted: (data) => {
      setStudents(data.list.students);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  return (
    <div className={clsx("border-b-2", type === "pass" ? "pb-12" : "pb-8")}>
      {students.length > 0 && (
        <RadioGroup value={selection} onChange={setSelection}>
          <RadioGroup.Label className="label">
            <span className="label-text font-semibold">{label}</span>
          </RadioGroup.Label>
          <div className="flex flex-col md:flex-row flex-wrap gap-2 w-full">
            {students.map((student) => (
              <RadioGroup.Option
                key={student.id}
                value={student}
                className={({ active, checked }) =>
                  clsx(
                    active && "ring-2 ring-offset-2 ring-primary",
                    checked
                      ? "bg-base-200 text-primary ring-1 ring-offset-1 ring-primary"
                      : "bg-white text-gray-900",
                    "relative md:basis-[49.3%] lg:basis-[32.75%] flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none"
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    {/* {type === "pass" && student.awaitingPassCount > 0 && (
                      <span className="absolute flex h-3 w-3">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
                      </span>
                    )}
                    {type === "enrolment" &&
                      student.awaitingEnrolmentCount > 0 && (
                        <span className="absolute flex h-3 w-3">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
                        </span>
                      )} */}
                    <div className="flex w-full items-center justify-between">
                      <div className="relative flex items-center gap-4 -z-0">
                        <div>
                          <img
                            src={`${BUCKET_URL}/${student.picture}`}
                            className="w-14 h-14 rounded-full"
                            alt={`${student.name}`}
                          />
                        </div>
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? "text-primary" : "text-gray-900"
                            }`}
                          >
                            {student.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? "text-gray-900" : "text-gray-500"
                            }`}
                          >
                            <span>
                              {student.gender} / {student.age} yrs.
                            </span>
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 fill-secondary">
                          <CheckCircleIcon className="h-5 w-5" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      )}
      {students.length === 0 && (
        <div className="flex flex-row gap-2">
          <p className="text-gray-500">No students found.</p>
        </div>
      )}
    </div>
  );
};
