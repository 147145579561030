export const PolicySection = (props) => {
  const { title, content } = props;

  return (
    <div className="pb-6 mb-6 flex flex-col gap-2 text-sm border-b-2">
      <h3 className="flex flex-row items-center">
        <span className="font-bold">{title}</span>
      </h3>
      <div className="flex flex-col gap-1">{content}</div>
    </div>
  );
};
