import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { MakePayment } from "../forms/MakePayment";
import { stripeAppearance } from "../../utils/constants";
import { PolicySection } from "./Policy";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK); // publishable key

export const PaymentSection = (props) => {
  const { clientSecret, amount, entity, policy } = props;

  const options = {
    clientSecret,
    appearance: stripeAppearance,
  };

  return (
    <>
      <section className="p-6 pb-8 bg-white rounded-lg">
        {policy && (
          <PolicySection title={policy.title} content={policy.content} />
        )}
        <Elements options={options} stripe={stripePromise}>
          <MakePayment secret={clientSecret} amount={amount} entity={entity} />
        </Elements>
      </section>
    </>
  );
};
