import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { USER_DATA } from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { StudentsGrid } from "../library/grid/Students";
import { CreateStudentModal } from "../library/modals/CreateStudent";
import { CreateStudentForm } from "../library/forms/CreateStudent";
import { TransactionsList } from "../library/lists/Transactions";

const validRoutes = [
  "pass",
  "enrolment",
  "order",
  "training",
  "performance",
  "hire",
];

function Home(props) {
  const notifyError = () => toast.error("Something went wrong!");
  const notifyLoading = () => toast.loading("Loading...");

  // get url search param "to"
  const [searchParams] = useSearchParams();
  const to = searchParams.get("to");

  // if to is present, redirect to the page
  useEffect(() => {
    if (to && validRoutes.includes(to)) window.location.assign(to);
  }, [to]);

  const { loading, data, error } = useQuery(USER_DATA);

  useEffect(() => {
    if (loading) notifyLoading();
  }, [loading]);

  useEffect(() => {
    if (data) toast.dismiss();
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.dismiss();
      notifyError();
    }
  }, [error]);

  return (
    <UserLayout title="Home | Swastik App">
      <div className="flex flex-col gap-8">
        <TransactionsList
          title={"Awaiting Payment"}
          status={["PENDING"]}
          timestamp={false}
          limit={5}
        />
        <section>
          <SectionHead heading="Students">
            <Link className="btn btn-outline" to="/pass">
              Add Pass
            </Link>
            <Link className="btn btn-outline" to="/enrolment">
              Add Enrolment
            </Link>
          </SectionHead>
          {data && data.data && (
            <div className="flex flex-col gap-2">
              <StudentsGrid students={data.data.students} />
              <CreateStudentModal
                title="Add a new student"
                content="To enrol for classes please add a student profile for the person
            enrolling to the class"
                label="Add Student"
              >
                <CreateStudentForm />
              </CreateStudentModal>
            </div>
          )}
        </section>
      </div>
    </UserLayout>
  );
}

export default Home;
