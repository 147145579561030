import { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SectionInfo } from "../library/sections/Information";
import { LocationSelector } from "../library/selector/Location";
import { HallSelector } from "../library/selector/Hall";
import { GET_HALL_AVAILABILITY_SLOTS } from "../utils/queries";
import { DRAFT_ENTITY } from "../utils/mutations";
import { toAmPm, formatter } from "../utils/helpers";

export default function Hire(props) {
  const [location, setLocation] = useState(null);
  const [hall, setHall] = useState(null);
  const [info, setInfo] = useState(null);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (location) setHall(null);
  }, [location]);

  return (
    <UserLayout title="Hall Hire | Swastik App">
      <section>
        {/* heading */}
        <SectionHead heading="Hall Hire"></SectionHead>
        {/* information */}
        <SectionInfo>
          <p>
            You can request to book private training sessions to improve skills
            for a dance form, prepare for an event performance as an individual
            or for a group.
          </p>
          <p>
            <strong>Step 1:</strong> Select a primary student account for the
            training. And fill in the details of the training request.
          </p>
          <p>
            <strong>Step 2:</strong> Submit the request for review and payment.
            On acceptance we will create the training plan, you will receive a
            payment request.
          </p>
          <p>
            <strong>Step 3:</strong> You'll find an option to pay for the
            training sessions on the Home page, under the Awaiting Payments
            section. Once the payment is confirmed, the training sessions will
            be scheduled.
          </p>
          <p>
            <i>
              <strong>Please note</strong> that once this request is submitted,
              we will review and plan sessions based on your inputs. Only once
              the payment is confirmed the sessions will be scheduled. We may
              contact you for further details if required.
            </i>
          </p>
        </SectionInfo>
        <LocationSelector
          label={`Select a location`}
          selection={location}
          setSelection={setLocation}
          disable={disable}
        />
        {location && (
          <HallSelector
            label={`Select a hall`}
            location={location}
            selection={hall}
            setSelection={setHall}
            disable={disable}
          />
        )}
        {location && hall && (
          <ScheduleForm
            location={location}
            hall={hall}
            info={info}
            clear={() => {
              setLocation(null);
              setHall(null);
              setInfo(null);
            }}
            freeze={() => setDisable(true)}
            unfreeze={() => setDisable(false)}
          />
        )}
      </section>
    </UserLayout>
  );
}

function ScheduleForm(props) {
  const { location, hall, clear, freeze, unfreeze } = props;

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [slots, setSlots] = useState({});
  const [allowScheduling, setAllowScheduling] = useState(false);
  const [scheduling, setScheduling] = useState(false);
  const [total, setTotal] = useState(0);
  const [validationDates, setValidationDates] = useState([]);
  const [allEntered, setAllEntered] = useState(false);

  const tomorrowsDate = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .split("T")[0];

  const twoMonthsFromNowDate = new Date(
    new Date().setMonth(new Date().getMonth() + 2)
  )
    .toISOString()
    .split("T")[0];

  const { control, watch, register, handleSubmit, reset } = useForm({
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "hire.schedule",
    rules: { required: true, minLength: 1, maxLength: 12 },
  });

  const purposeEntered = useWatch({ control, name: "hire.purpose" });
  const sessionsEntered = useWatch({ control, name: "hire.sessions" });
  const durationEntered = useWatch({ control, name: "hire.duration" });
  const scheduleEntered = useWatch({ control, name: "hire.schedule" });
  const termsAgreed = useWatch({ control, name: "hire.terms" });

  const scheduleFields = watch("hire.schedule");

  const [getAvailableSlots] = useLazyQuery(GET_HALL_AVAILABILITY_SLOTS, {
    onCompleted: (data) => {
      if (data && data.slots.length > 0) {
        const date = data.slots[0].date;
        setSlots((prev) => ({
          ...prev,
          [date]: data.slots,
        }));
      } else {
        toast.error("No timeslots available for the given date.");
      }
    },
    onError: () => {
      toast.error(
        "No timeslots available for the given date. Please try another date."
      );
    },
    fetchPolicy: "network-only",
  });

  const [draftHireEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        reset(); // reset the data
        clear(); // clear the form
        // navigate to the transactions page with the transaction id
        navigate(`/transactions/${data.transaction}`);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
  });

  const updateAvailableSlots = (date) => {
    getAvailableSlots({
      variables: {
        hallId: hall.id,
        date,
        duration: parseInt(durationEntered),
      },
    });
  };

  const setScheduleFields = () => {
    remove();

    const obj = { date: "", time: "" };

    const arr = Array.from({ length: sessionsEntered }, () => obj);

    append(arr);
    setScheduling(true);
  };

  const resetScheduleFields = () => {
    remove();
    setScheduling(false);
    setSlots({});
  };

  const onSubmit = (data) => {
    // set schedule (from: timestamp, to: timestamp) based on the date, time and duration
    const schedule = data.hire.schedule.map((v) => {
      const { date, time: id } = v;
      // based on the id, get the slot
      const slot = slots[date].find((slot) => slot.id === id);

      return { from: slot.from, to: slot.to };
    });

    const input = {
      type: "HIRE",
      hire: {
        userId: user.data.id,
        locationId: location.id,
        hallId: hall.id,
        purpose: data.hire.purpose,
        duration: parseInt(durationEntered),
        schedule: schedule,
        total: parseInt(total),
        deposit: parseInt(hall.deposit),
      },
    };

    draftHireEntity({ variables: { ...input } });
  };

  useEffect(() => {
    if (!!sessionsEntered && !!durationEntered) {
      setAllowScheduling(true);
    } else {
      setAllowScheduling(false);
    }
  }, [sessionsEntered, durationEntered]);

  useEffect(() => {
    if (hall && sessionsEntered && durationEntered) {
      const hourlyRate = hall.price;
      const pricePerMin = hourlyRate / 60;
      const durationAmount = parseInt(durationEntered) * parseInt(pricePerMin);
      const totalCost = durationAmount * parseInt(sessionsEntered);

      setTotal(totalCost + hall.deposit);
    } else {
      setTotal(0);
    }
  }, [hall, sessionsEntered, durationEntered]);

  useEffect(() => {
    if (scheduling) freeze();
    else unfreeze();
  }, [scheduling, freeze, unfreeze]);

  useEffect(() => {
    if (scheduleEntered?.length > 0) {
      const dates = scheduleEntered.map((schedule, i) => {
        const dateSet = !!schedule.date ? new Date(schedule.date) : new Date();
        !!schedule.date
          ? dateSet.setDate(dateSet.getDate() + 1)
          : dateSet.setDate(dateSet.getDate() + (i + 2));
        return dateSet.toISOString().split("T")[0];
      });

      setValidationDates(dates);
    }
  }, [scheduleEntered]);

  useEffect(() => {
    if (durationEntered && sessionsEntered) {
      const check = (field) => !!field.date && !!field.time;
      if (scheduleEntered?.length > 0) {
        setAllEntered(scheduleEntered.every(check));
      } else setAllEntered(false);
    } else {
      setAllEntered(false);
    }
  }, [durationEntered, sessionsEntered, scheduleEntered]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
        {/* text purpose */}
        <div className="basis-full">
          <label className="label">
            <span className="label-text font-semibold">Purpose</span>
          </label>
          <textarea
            className="textarea h-20 textarea-bordered bg-white w-full"
            placeholder="Enter purpose and preferences for the training"
            {...register("hire.purpose", {
              required: true,
            })}
            disabled={termsAgreed}
          ></textarea>
        </div>
        {!!purposeEntered && (
          <div className="border-b-2 pb-4 flex gap-4">
            {/* No. of sessions */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">
                  No. of Sessions
                </span>
              </label>
              <input
                type="number"
                className="input input-bordered w-full bg-white"
                min={1}
                max={10}
                disabled={scheduling}
                placeholder="Enter no. of sessions"
                {...register("hire.sessions", {
                  required: true,
                  min: 1,
                  max: 10,
                })}
              />
            </div>
            {/* duration */}
            <div className="basis-1/2">
              <label className="label">
                <span className="label-text font-semibold">
                  Session Duration
                </span>
              </label>
              <select
                className="select select-bordered w-full bg-white"
                disabled={scheduling}
                {...register("hire.duration", { required: true })}
              >
                <option value="">Select duration</option>
                <option value={60}>1 hr.</option>
                <option value={120}>2 hrs.</option>
                <option value={180}>3 hrs.</option>
                <option value={240}>4 hrs.</option>
                <option value={300}>5 hrs.</option>
                <option value={360}>6 hrs.</option>
                <option value={420}>7 hrs.</option>
                <option value={480}>8 hrs.</option>
                <option value={540}>9 hrs.</option>
              </select>
            </div>
          </div>
        )}
        {/* repeating select slot */}
        {!!durationEntered && (
          <li className="list-none divide-y-2">
            {fields.map((field, index) => (
              <ul key={field.id} className="flex flex-col pt-2 pb-4">
                <label className="label">
                  <span className="label-text font-semibold">
                    {index + 1}: Select Date & Time
                  </span>
                </label>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="basis-1/2">
                    <input
                      type="date"
                      min={
                        index === 0 ? tomorrowsDate : validationDates[index - 1]
                      }
                      max={twoMonthsFromNowDate}
                      className="input input-bordered w-full bg-white"
                      {...register(`hire.schedule.${index}.date`, {
                        onChange: (e) =>
                          updateAvailableSlots(e.target.value, index),
                        required: true,
                      })}
                      disabled={termsAgreed}
                    />
                  </div>
                  <div className="basis-1/2">
                    <select
                      className="select select-bordered w-full bg-white"
                      {...register(`hire.schedule.${index}.time`, {
                        required: true,
                      })}
                      disabled={termsAgreed}
                    >
                      <option value="">Select time</option>
                      {slots[scheduleFields[index]?.date]?.map((slot) => (
                        <option key={slot.id} value={slot.id}>
                          {toAmPm(slot.start)} - {toAmPm(slot.end)}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </ul>
            ))}
          </li>
        )}
        {!!purposeEntered &&
          !termsAgreed &&
          (!scheduling ? (
            <button
              className="btn btn-outline"
              disabled={!allowScheduling}
              onClick={() => setScheduleFields()}
            >
              Set Schedule
            </button>
          ) : (
            <button
              className="btn btn-outline"
              onClick={() => resetScheduleFields()}
            >
              Clear Schedule
            </button>
          ))}
        {/* checkbox terms */}
        <div className="flex mt-4">
          <label className="cursor-pointer label">
            <input
              type="checkbox"
              className="checkbox"
              disabled={!allEntered}
              {...register("hire.terms", {
                required: true,
              })}
            />
            <span className="ml-2 label-text font-medium">
              By submitting this request, I confirm that I've read and I agree
              to the terms and conditions of hall hiring set by Swastik Dance
              Academy (
              <a
                href="/files/hire-terms.pdf"
                target="_blank"
                aria-label="read the terms"
                className="underline underline-offset-2"
              >
                click here
              </a>{" "}
              to read to the document). I understand that once the payment is
              confirmed, the hall will be booked for the selected duration and
              time. I also understand that any damages to the property will be
              charged to me as per the terms.
            </span>
          </label>
        </div>
        <div className="flex flex-col justify-center items-center">
          <label className="label">
            <span className="label-text font-semibold">
              Cost:{" "}
              {formatter.format((total === 0 ? 0 : total - hall.deposit) / 100)}{" "}
              + Refundable Deposit: {formatter.format(hall.deposit / 100)}
            </span>
          </label>
        </div>
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary mt-6", loading && "loading")}
          disabled={!termsAgreed}
        >
          Finalise Hire, Pay {formatter.format(total / 100)}
        </button>
      </form>
      <button
        className="btn btn-outline w-full mt-2"
        onClick={() => {
          resetScheduleFields();
          reset();
          clear();
          unfreeze();
        }}
      >
        Cancel
      </button>
    </>
  );
}
