import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { formatter } from "../../utils/helpers";
import toast from "react-hot-toast";
import { LIST_PRODUCTS } from "../../utils/queries";

const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

export const ProductsSelector = (props) => {
  const { cart, setCart, disable = false } = props;

  const [availableProducts, setAvailableProducts] = useState([]);
  const [products, setProducts] = useState([]);

  useQuery(LIST_PRODUCTS, {
    variables: { status: "AVAILABLE" },
    onCompleted: (data) => {
      if (data.products && data.products.length > 0) {
        setAvailableProducts(data.products);
        setProducts(data.products);
        // setCart(savedCart);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const addToCart = (product) => {
    // if product already exits in the cart update quantity
    if (cart.find((item) => item.product === product.id)) {
      setCart(
        cart.map((item) =>
          item.product === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    } else {
      setCart([
        ...cart,
        {
          product: product.id,
          quantity: 1,
          price: product.price,
          thumbnail: product.thumbnail,
          name: product.name,
        },
      ]);
    }
  };

  const removeFromCart = (productId) => {
    setCart(cart.filter((item) => item.product !== productId));
  };

  const updateQuantity = (productId, quantity) => {
    if (quantity <= 0) {
      return removeFromCart(productId);
    }
    setCart(
      cart.map((item) =>
        item.product === productId ? { ...item, quantity } : item
      )
    );
  };

  useEffect(() => {
    // filter products to show only those which are not in the saved cart
    setProducts(
      availableProducts.filter(
        (product) => !cart.find((item) => item.product === product.id)
      )
    );
  }, [cart, availableProducts, setCart]);

  return (
    <>
      {/* list */}
      <div className="relative border-b-2 pb-6 pt-4 md:pt-0 overflow-y-visible">
        <div className="label">
          <span className="label-text font-semibold">Add products</span>
        </div>
        <div className="flex flex-nowrap gap-6 overflow-x-scroll overflow-y-visible pb-6">
          {products.length > 0 ? (
            products.map((product) => (
              <div
                key={product.id}
                className="w-64 flex-shrink-0 bg-white rounded-xl p-3 relative shadow-md hover:shadow-lg transition duration-300 ease-in-out cursor-pointer flex flex-col gap-2"
              >
                <div>
                  <img
                    src={`${BUCKET_URL}/${product.thumbnail}`}
                    alt={product.title}
                    className="w-full rounded-md"
                  />
                </div>
                <div className="flex flex-col gap-2 p-2 justify-between h-full">
                  <div className="flex flex-col gap-2">
                    <h3 className="text-xl font-medium">{product.name}</h3>
                    <p className="text-sm text-gray-500">
                      {product.description}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="text-center border-t pt-2 mt-2 font-medium text-gray-600 font-mono text-lg">
                      {formatter.format(product.price / 100)}
                    </p>
                    <button
                      className="border-2 rounded-md px-4 py-2 font-medium border-primary text-primary hover:text-white hover:bg-primary disabled:opacity-10 disabled:cursor-not-allowed disabled:hover:text-primary disabled:hover:bg-white transition duration-300 ease-in-out"
                      onClick={() => addToCart(product)}
                      disabled={disable}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500 w-full p-8">
              No products available
            </p>
          )}
        </div>
      </div>
      {/* cart */}
      <div className="relative border-b-2 pb-8">
        <div className="label">
          <span className="label-text font-semibold">
            View cart{" "}
            {`(${cart.length} ${cart.length === 1 ? "item" : "items"})`}
          </span>
        </div>
        {/* list cart products */}
        <div className="bg-white rounded-xl p-4 divide-y">
          {cart.length > 0 ? (
            cart.map((item) => (
              <div
                key={item.product}
                className="flex flex-col md:flex-row md:items-center justify-between gap-4 p-2"
              >
                <div className="flex items-center gap-4 w-full">
                  <img
                    src={`${BUCKET_URL}/${item.thumbnail}`}
                    alt={item.name}
                    className="w-16 h-16 rounded-md"
                  />
                  <div className="flex flex-col md:flex-row gap-2 flex-grow justify-between">
                    <div>
                      <h3 className="text-lg font-medium">{item.name}</h3>
                      <p className="text-sm text-gray-500">
                        Price: {formatter.format(item.price / 100)} | Qty:{" "}
                        {item.quantity} | Total:{" "}
                        {formatter.format((item.price * item.quantity) / 100)}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      <button
                        className="btn btn-outline btn-xs btn-circle btn-primary"
                        onClick={() =>
                          updateQuantity(item.product, item.quantity - 1)
                        }
                        disabled={disable}
                      >
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button
                        className="btn btn-outline btn-xs btn-circle btn-primary"
                        onClick={() =>
                          updateQuantity(item.product, item.quantity + 1)
                        }
                        disabled={disable}
                      >
                        +
                      </button>
                      <button
                        className="btn btn-outline btn-xs"
                        onClick={() => removeFromCart(item.product)}
                        disabled={disable}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">Cart is empty</p>
          )}
        </div>
        <div>
          <p className="text-center text-gray-700 font-bold mt-4">
            Total:{" "}
            <span className="font-mono">
              {formatter.format(
                cart.reduce(
                  (acc, item) => acc + item.price * item.quantity,
                  0
                ) / 100
              )}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
