import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useForm, useWatch, useFormState } from "react-hook-form";
import clsx from "clsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SectionInfo } from "../library/sections/Information";
import { LocationSelector } from "../library/selector/Location";
import { REQUEST_ENTITY } from "../utils/mutations";

export default function Training(props) {
  const [location, setLocation] = useState(null);
  const [disable, setDisable] = useState(false);

  return (
    <UserLayout title="Private Lesson | Swastik App">
      <section>
        {/* heading */}
        <SectionHead heading="Private Training"></SectionHead>
        {/* information */}
        <SectionInfo>
          <p>
            You can request to book private training sessions to improve skills
            for a dance form, prepare for an event performance as an individual
            or for a group.
          </p>
          <p>
            <strong>Step 1:</strong> Select a preferred studio location. And
            fill in the details of the training request.
          </p>
          <p>
            <strong>Step 2:</strong> Submit the request for review and payment.
            On acceptance we will create the training plan, you will receive a
            payment request.
          </p>
          <p>
            <strong>Step 3:</strong> You'll find an option to pay for the
            training sessions on the Home page, under the Awaiting Payments
            section. Once the payment is confirmed, the training sessions will
            be scheduled.
          </p>
          <p>
            <i>
              <strong>Please note</strong> that once this request is submitted,
              we will review and plan sessions based on your inputs. Only once
              the payment is confirmed the sessions will be scheduled. We may
              contact you for further details if required.
            </i>
          </p>
        </SectionInfo>
        {/* select location */}
        <LocationSelector
          label={`Select a location for training`}
          selection={location}
          setSelection={setLocation}
          disable={disable}
        />
        {/* submit information */}
        {location && (
          <SubmitInformation
            location={location}
            freeze={() => setDisable(true)}
            unfreeze={() => setDisable(false)}
            clear={() => {
              setLocation(null);
            }}
          />
        )}
      </section>
    </UserLayout>
  );
}

function SubmitInformation(props) {
  const { location, freeze, unfreeze, clear } = props;

  const { user } = useContext(AuthContext);

  const { control, register, handleSubmit, reset, setValue } = useForm({
    mode: "onChange",
  });

  const { isValid } = useFormState({ control });

  const trainingType = useWatch({ control, name: "training.type" });
  const termsAgreed = useWatch({ control, name: "training.terms" });

  const [requestEntity, { loading }] = useMutation(REQUEST_ENTITY, {
    onCompleted: (data) => {
      if (data.entity) {
        clear();
        reset();
        unfreeze();

        toast.success("Request submitted successfully!");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
  });

  const onSubmit = (data) => {
    const { training } = data;

    const input = {
      type: "TRAINING",
      training: {
        userId: user.data.id,
        locationId: location.id,
        type: training.type,
        pack: parseInt(training.packSize),
        purpose: training.purpose,
        experience: training.experience,
        availability: training.availability,
      },
    };

    requestEntity({ variables: { ...input } });
  };

  useEffect(() => {
    if (trainingType === "SINGLE") {
      setValue("training.packSize", 1);
    }
    if (trainingType === "GROUP") {
      setValue("training.packSize", 2);
    }
  }, [trainingType, setValue]);

  useEffect(() => {
    if (termsAgreed) freeze();
    else unfreeze();
  }, [termsAgreed, freeze, unfreeze]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
        <div className="flex gap-4">
          {/* select type */}
          <div className="basis-1/2">
            <label className="label">
              <span className="label-text font-semibold">Select Type</span>
            </label>
            <select
              className="select select-bordered bg-white w-full"
              {...register("training.type", {
                required: true,
              })}
            >
              <option value="">Select type</option>
              <option value="SINGLE">Individual</option>
              <option value="GROUP">Group</option>
            </select>
          </div>
          {/* if type == "group", enter pack size */}
          <div className="basis-1/2 mb-2">
            <label className="label">
              <span className="label-text font-semibold">
                Pack Size (no. of people)
              </span>
            </label>
            <input
              type="number"
              className="input input-bordered bg-white w-full"
              placeholder="Enter pack size"
              min={trainingType === "GROUP" ? 2 : 1}
              disabled={trainingType === "SINGLE"}
              {...register("training.packSize", {
                required: true,
                min: trainingType === "GROUP" ? 2 : 1,
                validate: (value) => {
                  if (trainingType === "GROUP" && value < 2) {
                    return "Pack size must be at least 2 for group training";
                  }
                  if (trainingType === "SINGLE" && value > 1) {
                    return "Pack size must be 1 for individual training";
                  }
                },
              })}
            />
          </div>
        </div>
        {/* text purpose */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Purpose</span>
          </label>
          <textarea
            className="textarea h-20 textarea-bordered bg-white w-full"
            placeholder="Enter purpose and preferences for the training"
            {...register("training.purpose", {
              required: true,
            })}
          ></textarea>
        </div>
        {/* text experience */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Experience</span>
          </label>
          <textarea
            className="textarea h-20 textarea-bordered bg-white w-full"
            placeholder="Enter experience level of the student"
            {...register("training.experience", {
              required: true,
            })}
          ></textarea>
        </div>
        {/* input availability */}
        <div>
          <label className="label">
            <span className="label-text font-semibold">Availability</span>
          </label>
          <textarea
            type="text"
            className="textarea h-20 textarea-bordered bg-white w-full"
            placeholder="Explain your availability (in terms of days, time, duration, until which date, etc.)"
            {...register("training.availability", {
              required: true,
            })}
          ></textarea>
        </div>
        {/* checkbox terms */}
        <div className="flex mt-4">
          <label className="cursor-pointer label">
            <input
              type="checkbox"
              className="checkbox"
              {...register("training.terms", {
                required: true,
              })}
            />
            <span className="ml-2 label-text font-medium">
              By submitting this request, I confirm that I've read and I agree
              to the terms and conditions of the training set by Swastik Dance
              Academy (
              <a
                href="/files/training-terms.pdf"
                target="_blank"
                aria-label="read the terms"
                className="underline underline-offset-2"
              >
                click here
              </a>{" "}
              to read to the document). I understand that this is a request for
              training and not a confirmation of the training sessions.
            </span>
          </label>
        </div>
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary mt-6", loading && "loading")}
          disabled={!isValid}
        >
          Submit Request
        </button>
      </form>
      {/* cancel */}
      <button
        className="btn btn-outline w-full mt-2"
        onClick={() => {
          clear();
          reset();
        }}
      >
        Cancel
      </button>
    </>
  );
}
