import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { ADD_STUDENT } from "../../utils/mutations";
import { USER_DATA } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export const CreateStudentForm = (props) => {
  const { register, handleSubmit, reset } = useForm();
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);

  const notifyError = () => toast.error("Unable to add student!");

  const [addStudent, { loading }] = useMutation(ADD_STUDENT, {
    onCompleted: () => {
      setSuccess(true);
      setLoader(false);
      reset();
    },
    onError: () => {
      setLoader(false);
      notifyError();
    },
    refetchQueries: [USER_DATA],
  });

  const onSubmit = (data) => {
    const { firstName, middleName, lastName, dob, gender, notes } =
      data.student;
    addStudent({
      variables: {
        input: {
          firstName,
          middleName,
          lastName,
          dob,
          gender,
          notes,
        },
      },
    });
  };

  const doneAction = () => {
    setSuccess(false);
  };

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  return (
    <div>
      {success ? (
        <div className="flex flex-col items-center gap-6">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 text-success"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <h3 className="text-3xl font-normal">Student created</h3>
          <div className="w-full mt-8">
            <label
              htmlFor="student-modal"
              className="btn w-full btn-outline"
              onClick={() => doneAction()}
            >
              Done
            </label>
          </div>
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
            {/* name */}
            <div className="flex flex-col justify-between space-y-3">
              <div className="w-full">
                <label className="label">
                  <span className="label-text font-semibold">First name</span>
                </label>
                <input
                  type="text"
                  placeholder="John"
                  className="input input-bordered w-full"
                  {...register("student.firstName")}
                  required
                />
              </div>
              <div className="w-full">
                <label className="label">
                  <span className="label-text font-semibold">Middle name</span>
                </label>
                <input
                  type="text"
                  placeholder="Fred"
                  className="input input-bordered w-full"
                  {...register("student.middleName")}
                />
              </div>
              <div className="w-full">
                <label className="label">
                  <span className="label-text font-semibold">Last name</span>
                </label>
                <input
                  type="text"
                  placeholder="Doe"
                  className="input input-bordered w-full"
                  {...register("student.lastName")}
                  required
                />
              </div>
            </div>
            {/* dob */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">Date of birth</span>
              </label>
              <input
                type="date"
                placeholder="Type here"
                className="input input-bordered w-full"
                {...register("student.dob")}
                required
              />
            </div>
            {/* gender */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">Gender</span>
              </label>
              <select
                defaultValue="Select"
                className="select select-bordered  w-full"
                {...register("student.gender")}
                required
              >
                <option disabled>Select</option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
              </select>
            </div>
            {/* notes */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">
                  Allergies or medical conditions
                </span>
              </label>
              <textarea
                className="textarea textarea-bordered w-full"
                placeholder="Allergies, conditions, etc."
                {...register("student.notes")}
              ></textarea>
            </div>
            <div className="flex flex-col pt-4 gap-2">
              <button
                type="submit"
                className={
                  loader
                    ? "btn btn-active w-full loading"
                    : "btn btn-active w-full"
                }
              >
                Add Student
              </button>
              <label
                htmlFor="student-modal-create"
                className="btn w-full btn-outline"
              >
                Cancel
              </label>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
