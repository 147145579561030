export const ClassesGrid = (props) => {
  const { schedule } = props.schedule;

  return (
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pt-4 pb-6">
      {schedule &&
        schedule.map((data) => {
          return (
            <div className="card w-full bg-white shadow-md" key={data.id}>
              <div className="card-body justify-between">
                <div>
                  <h3 className="text-xl font-semibold pb-2">{data.name}</h3>
                  <p className="text-sm font-semibold">Every {data.schedule}</p>
                  {data.preassessment && (
                    <p className="text-sm font-bold italic">
                      (pre-assessment required for admission)
                    </p>
                  )}
                </div>
                <div>
                  <p className="text-sm">
                    {data.remaining} sessions available |{" "}
                    {formatter.format(data.price / 100)} per session
                  </p>
                  <p className="text-lg font-semibold text-neutral">
                    {formatter.format((data.price * data.remaining) / 100)}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});
