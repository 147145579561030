import { createRef, useRef, useState, useEffect } from "react";
import toast from "react-hot-toast";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import AWS from "aws-sdk";

const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;
const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const StudentPicture = (params) => {
  const dropzoneRef = createRef();
  const editor = useRef(null);

  const { studentId, picture, update } = params;

  const [image, setImage] = useState(null);
  const [changed, setChanged] = useState(false);

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleUpdate = async () => {
    if (editor) {
      const canvasScaled = editor.current.getImageScaledToCanvas().toDataURL();

      const result = await fetch(canvasScaled);
      const blob = await result.blob();
      const file = new File([blob], `${studentId}.jpg`, { type: "image/jpeg" });

      const params = {
        ACL: "public-read",
        Body: file,
        Key: `pictures/${studentId}.${file.type.split("/")[1]}`,
      };

      myBucket
        .putObject(params)
        .on("complete", () => {
          update(`pictures/${studentId}.${file.type.split("/")[1]}`);
        })
        .send((err) => {
          if (err) toast.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (picture) {
      setImage(`${BUCKET_URL}/${picture}`);
    } else {
      setImage(null);
    }
  }, [picture]);

  useEffect(() => {
    const pictureUrl = `${BUCKET_URL}/${picture}`;
    if (image && image === pictureUrl) setChanged(false);
    else setChanged(true);
  }, [image, picture]);

  return (
    <div className="mb-4">
      <label className="label">
        <span className="label-text font-semibold">Picture</span>
      </label>
      <Dropzone
        ref={dropzoneRef}
        onDrop={(dropped) => setImage(dropped[0])}
        noKeyboard
        noClick
        maxFiles={1}
        accept={["image/*"]}
        style={{ width: "200px", height: "200px" }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <AvatarEditor
              ref={editor}
              width={200}
              height={200}
              border={5}
              borderRadius={100}
              image={image}
              disableHiDPIScaling={true}
            />
            <input {...getInputProps()} />
            <button
              type="button"
              className="btn btn-xs btn-ghost mt-2"
              onClick={() => openDialog()}
            >
              Select picture
            </button>
          </div>
        )}
      </Dropzone>
      <button
        className="btn btn-sm btn-outline mt-2"
        onClick={async () => await handleUpdate()}
        disabled={!changed || !image}
      >
        Update Picture
      </button>
    </div>
  );
};
