import { useState } from "react";
import { UpdateStudentModal } from "../modals/UpdateStudent";
import { UpdateStudentForm } from "../forms/UpdateStudent";
import { DELETE_STUDENT } from "../../utils/mutations";
import { USER_DATA } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";

const BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;

export const StudentsGrid = (props) => {
  const { students } = props;
  const [update, setUpdate] = useState(null);

  const notifyError = (message) => toast.error(message);

  const [deleteStudent] = useMutation(DELETE_STUDENT, {
    refetchQueries: [USER_DATA],
    onError: (error) => {
      notifyError(error.message);
    },
  });

  return (
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pt-4 pb-4">
      {students &&
        students.map((student) => (
          <div className="card w-full bg-white shadow-md" key={student.id}>
            <div className="card-body gap-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-4">
                  {student.picture ? (
                    <img
                      src={`${BUCKET_URL}/${student.picture}`}
                      className="w-16 h-16 rounded-full"
                      alt={`${student.name}`}
                    />
                  ) : (
                    <img
                      src={`${BUCKET_URL}/pictures/placeholder.jpg`}
                      className="w-16 h-16 rounded-full"
                      alt={`${student.name}`}
                    />
                  )}
                  <div className="flex flex-col">
                    <h3 className="text-2xl font-semibold">{student.name}</h3>
                    <p>
                      {student.gender} - {student.age} years
                    </p>
                  </div>
                </div>
                <div className="flex justify-end gap-1">
                  <div className="space-x-2">
                    {/* <Link className="btn btn-sm" to={`/enrol/${student.id}`}>
                    Enrol
                  </Link> */}
                    <label
                      htmlFor="student-modal-update"
                      className="btn btn-sm btn-ghost btn-circle"
                      onClick={() => setUpdate(student.id)}
                    >
                      <PencilSquareIcon className="h-5 w-5" />
                    </label>
                  </div>
                  <button
                    className="btn btn-ghost btn-sm btn-circle"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to delete the student information?"
                        )
                      )
                        deleteStudent({
                          variables: { input: { id: student.id } },
                        });
                    }}
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      <UpdateStudentModal
        title="Update student information"
        content="This information will be used for student enrolment to the class"
        btn="btn btn-sm btn-outline hidden"
        label={"Update"}
      >
        {update && <UpdateStudentForm student={update} />}
      </UpdateStudentModal>
    </div>
  );
};
