import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CANCEL_ENTITY } from "../../utils/mutations";
import { useNavigate } from "react-router-dom";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import clsx from "clsx";
import toast from "react-hot-toast";
import { formatter } from "../../utils/helpers";

const successUrl = process.env.REACT_APP_SUCCESS_URL;

export const MakePayment = (props) => {
  const { amount, entity } = props;

  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [cancelEntity, { loading: cancelling }] = useMutation(CANCEL_ENTITY, {
    variables: { ...entity },
    onError: (error) => {
      console.error(error.message);
      toast.error("Unable to cancel the entity.");
    },
    onCompleted: () => {
      toast.success("Cancelled successfully.");
      navigate("/home");
    },
  });

  const handleCancel = () => {
    const typeLabel = entity?.type?.toLowerCase() ?? "";
    // confirm cancellation
    if (
      window.confirm(
        `Are you sure you want to cancel this ${typeLabel} payment?`
      )
    ) {
      cancelEntity();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: successUrl,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      toast.error(error.message);
    } else {
      toast.error("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div tabIndex={0}>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button
          className={clsx(
            "btn btn-primary w-full mt-4",
            isLoading && "loading"
          )}
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">{`Pay ${formatter.format(
            amount / 100
          )}`}</span>
        </button>
      </form>
      {/* cancel */}
      {entity && (
        <button
          className={clsx(
            "btn btn-outline w-full mt-2",
            cancelling && "loading"
          )}
          onClick={() => handleCancel()}
        >
          Cancel
        </button>
      )}
    </div>
  );
};
