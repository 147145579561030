import { useState } from "react";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import clsx from "clsx";
import { LIST_TERMS } from "../../utils/queries";

export const TermSelector = (props) => {
  const { selection, setSelection, type } = props;

  const [terms, setTerms] = useState([]);

  useQuery(LIST_TERMS, {
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (data) => {
      if (data.terms && data.terms.length > 0) {
        // reverse the terms so that the most recent term is first
        const reversed = [...data.terms].reverse();
        setTerms(reversed);
        setSelection(reversed[0]);
      }
    },
    variables: {
      status: ["CURRENT", "UPCOMING"],
    },
  });

  return (
    <div className={clsx("border-b-2", type === "pass" ? "pb-10" : "pb-8")}>
      {terms && terms.length > 0 && (
        <div className="w-full">
          <label className="label">
            <span className="label-text font-semibold">Filter by term</span>
          </label>
          <select
            className="select select-bordered w-full bg-white"
            value={selection ? selection.id : ""}
            onChange={(e) =>
              setSelection(terms.find((term) => term.id === e.target.value))
            }
          >
            <option value="">Select a term</option>
            {terms.map((term) => (
              <option key={term.id} value={term.id}>
                {term.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};
