import { gql } from "@apollo/client";

export const GET_CLASSES = gql`
  mutation Schedule($input: scheduleFilter) {
    schedule(input: $input) {
      id
      name
      price
      schedule
      remaining
      preassessment
    }
  }
`;

export const GET_AVAILABLE_CLASSES = gql`
  mutation Schedule($input: scheduleFilter) {
    schedule(input: $input) {
      id
      name
      price
      schedule
      remaining
      preassessment
    }
  }
`;

export const ADD_STUDENT = gql`
  mutation CreateStudent($input: studentProfile) {
    student: createStudent(input: $input)
  }
`;

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($input: studentProfile) {
    student: updateStudent(input: $input)
  }
`;

export const DELETE_STUDENT = gql`
  mutation RemoveStudent($input: studentProfile) {
    removeStudent(input: $input)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation Update($input: userProfile) {
    update(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation Change($password: String) {
    change(password: $password)
  }
`;

export const VALIDATE_VOUCHER = gql`
  mutation ValidateVoucher($type: String!, $code: String!) {
    valid: validateVoucher(type: $type, code: $code)
  }
`;

export const CREATE_PAYMENT_INTENT = gql`
  mutation OnlinePaymentIntent($input: PaymentIntet) {
    secret: onlinePaymentIntent(input: $input)
  }
`;

export const ADD_STUDENT_PASS = gql`
  mutation AddPass($input: PassDetails) {
    action: addPass(input: $input)
  }
`;

export const USE_STUDENT_PASS = gql`
  mutation UsePass(
    $usePassId: ID!
    $classId: ID!
    $sessionId: ID!
    $category: String!
  ) {
    action: usePass(
      id: $usePassId
      classId: $classId
      sessionId: $sessionId
      category: $category
    )
  }
`;

export const REQUEST_ENTITY = gql`
  mutation RequestEntity(
    $type: String!
    $training: TrainingDetails
    $performance: PerformanceDetails
  ) {
    entity: requestEntity(
      type: $type
      training: $training
      performance: $performance
    )
  }
`;

export const DRAFT_ENTITY = gql`
  mutation DraftEntity(
    $type: String!
    $enrolment: EnrolmentDetails
    $pass: PassDetails
    $order: OrderDetails
    $training: TrainingDetails
    $performance: PerformanceDetails
    $hire: HireDetails
  ) {
    transaction: draftEntity(
      type: $type
      enrolment: $enrolment
      pass: $pass
      order: $order
      training: $training
      performance: $performance
      hire: $hire
    )
  }
`;

export const CANCEL_ENTITY = gql`
  mutation CancelEntity($type: String!, $entityId: ID!) {
    entity: cancelEntity(type: $type, entityId: $entityId)
  }
`;

export const USE_ENTITY = gql`
  mutation UseEntity($type: String!, $entityId: ID!, $pass: PassDetails) {
    entity: useEntity(type: $type, entityId: $entityId, pass: $pass)
  }
`;
