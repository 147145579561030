import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import toast from "react-hot-toast";
import clsx from "clsx";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { PASSES_LIST } from "../../utils/queries";
import { formatter } from "../../utils/helpers";

export const PassSelector = (props) => {
  const {
    term,
    student,
    selection,
    setSelection,
    category,
    setCategory,
    setCanAddPass,
  } = props;

  const [passes, setPasses] = useState([]);

  useQuery(PASSES_LIST, {
    variables: {
      studentId: student.id,
      termId: term.id,
      category,
    },
    onCompleted: (data) => {
      if (category === "DROPIN") {
        const filtered = data.list.passes.filter(
          (pass) => pass.status === "APPROVED"
        );
        setPasses(filtered);
      } else {
        const filtered = data.list.passes.filter(
          (pass) => pass.status === "APPROVED" || pass.status === "USED"
        );
        setPasses(filtered);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const shouldBeDisabled = (pass) => {
    if (pass.category === "DROPIN") {
      return pass.status !== "APPROVED";
    } else if (pass.category === "UNLIMITED") {
      return pass.status !== "APPROVED" && pass.status !== "USED";
    } else {
      return false;
    }
  };

  useEffect(() => {
    setSelection(null);
    if (student && passes.length > 0) {
      if (category === "UNLIMITED") {
        setCanAddPass(false);
      } else {
        setCanAddPass(true);
      }
    } else {
      setCanAddPass(true);
    }
  }, [student, passes, category, setCanAddPass, setSelection]);

  return (
    <div className="relative border-b-2 pb-10 mb-4 pt-4 md:pt-0">
      {term && (
        <div>
          <div className="absolute w-full -top-7">
            <div className="w-full flex flex-row text-center justify-center">
              <select
                className="select select-bordered w-full max-w-fit text-center bg-white"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="DROPIN">Drop In Pass</option>
                <option value="UNLIMITED">Unlimited Term Pass</option>
              </select>
            </div>
          </div>
          {/* dropdown filters for options */}
          <div className="pt-4">
            {/* passes */}
            <RadioGroup value={selection} onChange={setSelection}>
              {passes.length > 0 && (
                <RadioGroup.Label className="label">
                  <span className="label-text font-semibold">
                    Select a pass
                  </span>
                </RadioGroup.Label>
              )}
              <div className="flex flex-col gap-2 w-full">
                {passes.length > 0 ? (
                  passes.map((pass) => (
                    <RadioGroup.Option
                      key={pass.id}
                      value={pass}
                      className={({ active, checked, disabled }) =>
                        clsx(
                          disabled
                            ? "bg-white/0 shadow-none border-2 cursor-not-allowed"
                            : "bg-white shadow-md cursor-pointer",
                          active && "ring-2 ring-offset-2 ring-primary",
                          checked
                            ? "bg-base-200 text-primary ring-1 ring-offset-1 ring-primary"
                            : "bg-white text-gray-900",
                          "relative flex rounded-lg px-5 py-4 focus:outline-none"
                        )
                      }
                      disabled={shouldBeDisabled(pass)}
                    >
                      {({ active, checked, disabled }) => (
                        <>
                          <div className="flex w-full items-center justify-between">
                            <div className="flex items-center gap-4">
                              <div className="text-sm">
                                <RadioGroup.Label
                                  as="p"
                                  className={`font-medium  ${
                                    checked ? "text-primary" : "text-gray-900"
                                  }`}
                                >
                                  {pass.name}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                  as="span"
                                  className={`inline ${
                                    checked ? "text-gray-900" : "text-gray-500"
                                  }`}
                                >
                                  {
                                    <span>
                                      Total:{" "}
                                      {formatter.format(
                                        (pass.total -
                                          (pass.voucher
                                            ? pass.voucher * 1
                                            : 0) -
                                          (pass.discount
                                            ? pass.discount * 1
                                            : 0)) /
                                          100
                                      )}{" "}
                                    </span>
                                  }
                                  {pass.status === "AWAITING" && (
                                    <span>
                                      | Awaiting payment of{" "}
                                      {formatter.format(
                                        (pass.total -
                                          (pass.voucher
                                            ? pass.voucher * 1
                                            : 0) -
                                          (pass.discount
                                            ? pass.discount * 1
                                            : 0)) /
                                          100
                                      )}
                                    </span>
                                  )}
                                  {(pass.status === "CENCELLED" ||
                                    pass.status === "REFUNDED") && (
                                    <span>
                                      | Refunded:{" "}
                                      {formatter.format(
                                        (pass.total -
                                          (pass.voucher
                                            ? pass.voucher * 1
                                            : 0) -
                                          (pass.discount
                                            ? pass.discount * 1
                                            : 0)) /
                                          100
                                      )}
                                    </span>
                                  )}
                                </RadioGroup.Description>
                              </div>
                            </div>
                            <div className="shrink-0 fill-secondary">
                              {checked && (
                                <CheckCircleIcon className="h-5 w-5" />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))
                ) : (
                  <div className="flex flex-row gap-2 justify-center w-full pt-6">
                    <p className="text-gray-500">No passes found.</p>
                  </div>
                )}
              </div>
            </RadioGroup>
          </div>
        </div>
      )}
      {selection && (
        <div className="w-full flex justify-center absolute -bottom-5">
          <h3 className="bg-white font-semibold text-base px-4 py-1.5 rounded-md capitalize">
            {selection
              ? `Use ${selection.category.toLowerCase()} Pass`
              : "Add New Pass"}
          </h3>
        </div>
      )}
    </div>
  );
};
