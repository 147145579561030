import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/authContext";
import Schedule from "./page/Schedule";
import Register from "./page/Register";
import Login from "./page/Login";
import Reset from "./page/Reset";
import Home from "./page/Home"; // manage student, view enrolments
import Enrolments from "./page/Enrolment"; // new enrolments
import Pass from "./page/Pass"; // dance class
import Order from "./page/Order"; // products order
import Training from "./page/Training"; // private training
import Performance from "./page/Performance"; // event performance
import Hire from "./page/Hire"; // hall hire
import Transactions from "./page/Transactions"; // view transactions
import Success from "./page/Success"; // enrolment successful
import Profile from "./page/Profile"; // manage profile
import Error from "./page/Error";

function App() {
  return (
    <Routes>
      {/* open routes */}
      <Route path="/" element={<Schedule />} />
      <Route path="*" element={<Error />} />
      {/* public routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoute>
            <Register />
          </PublicRoute>
        }
      />
      <Route
        path="/reset"
        element={
          <PublicRoute>
            <Reset />
          </PublicRoute>
        }
      />
      {/* customer routes */}
      <Route
        path="/home"
        element={
          <CustomerRoute>
            <Home />
          </CustomerRoute>
        }
      />
      <Route
        path="/enrolment"
        element={
          <CustomerRoute>
            <Enrolments />
          </CustomerRoute>
        }
      />
      <Route
        path="/pass"
        element={
          <CustomerRoute>
            <Pass />
          </CustomerRoute>
        }
      />
      <Route
        path="/order"
        element={
          <CustomerRoute>
            <Order />
          </CustomerRoute>
        }
      />
      <Route
        path="/training"
        element={
          <CustomerRoute>
            <Training />
          </CustomerRoute>
        }
      />
      <Route
        path="/performance"
        element={
          <CustomerRoute>
            <Performance />
          </CustomerRoute>
        }
      />
      <Route
        path="/hire"
        element={
          <CustomerRoute>
            <Hire />
          </CustomerRoute>
        }
      />
      <Route
        path="/transactions"
        element={
          <CustomerRoute>
            <Transactions />
          </CustomerRoute>
        }
      />
      <Route
        path="/transactions/:id"
        element={
          <CustomerRoute>
            <Transactions />
          </CustomerRoute>
        }
      />
      <Route
        path="/success"
        element={
          <CustomerRoute>
            <Success />
          </CustomerRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <CustomerRoute>
            <Profile />
          </CustomerRoute>
        }
      />
    </Routes>
  );
}

export default App;

function CustomerRoute(props) {
  const { user } = useContext(AuthContext);
  // customer but not admin
  return (
    <>
      {user && !user.data.admin ? (
        props.children
      ) : (
        <Navigate to={"/login"} replace />
      )}
    </>
  );
}

function PublicRoute(props) {
  const { user } = useContext(AuthContext);
  // not logged in
  return <>{!user ? props.children : <Navigate to={"/home"} replace />}</>;
}
