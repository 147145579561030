import { gql } from "@apollo/client";

export const USER_DATA = gql`
  query UserData {
    data: userData {
      students {
        id
        name
        picture
        dob
        age
        gender
      }
      enrolments {
        id
        name
        student {
          firstName
          middleName
          lastName
        }
        class {
          name
          price
        }
        from
        total
      }
    }
  }
`;

export const STUDENT_INFO = gql`
  query StudentInfo($id: ID) {
    student: studentInfo(id: $id) {
      id
      firstName
      middleName
      lastName
      picture
      dob
      gender
      notes
    }
  }
`;

export const SCHEDULE_FILTER = gql`
  query FilterOptions {
    filters: filterOptions {
      locations {
        id
        name
      }
      terms {
        id
        name
        start
        end
      }
      programs {
        id
        name
      }
      groups {
        id
        name
      }
    }
  }
`;

export const ENROL_FILTER = gql`
  query FilterOptions {
    filters: filterOptions {
      terms {
        id
        name
        start
        end
      }
      locations {
        id
        name
      }
      programs {
        id
        name
      }
      groups {
        id
        name
        min
        max
      }
    }
  }
`;

export const PROFILE_INFO = gql`
  query ProfileInfo {
    info: profileInfo {
      id
      email
      phone
      street
      suburb
      postcode
    }
  }
`;

export const LIST_TERMS = gql`
  query ListTerms($status: [String]) {
    terms: listTerms(status: $status) {
      id
      name
      start
      end
      status
      dropIn
      unlimited
    }
  }
`;

export const STUDENTS_LIST = gql`
  query Students(
    $userId: ID
    $search: String
    $limit: Int
    $page: Int
    $status: String
  ) {
    list: students(
      userId: $userId
      search: $search
      limit: $limit
      page: $page
      status: $status
    ) {
      total
      students {
        id
        user {
          id
          email
          phone
          street
          suburb
          postcode
        }
        picture
        name
        dob
        age
        gender
        notes
        status
        commentsCount
        commentsCountStaff
        commentsCountFlagged
        groups {
          id
          name
        }
        awaitingEnrolmentCount
        awaitingPassCount
      }
    }
  }
`;

export const CLASSES_LIST = gql`
  query Classes(
    $termsId: [ID]
    $locationsId: [ID]
    $programsId: [ID]
    $groupsId: [ID]
    $name: String
    $status: String
  ) {
    list: classes(
      termsId: $termsId
      locationsId: $locationsId
      programsId: $programsId
      groupsId: $groupsId
      name: $name
      status: $status
    ) {
      total
      classes {
        id
        name
        price
        day
        from
        to
        term {
          id
          name
          start
          end
          dropIn
          unlimited
        }
        program {
          id
          name
        }
        group {
          id
          name
          min
          max
        }
        location {
          id
          name
          suburb
        }
        preassessment
        status
        enrolmentCount
        dropinCount
        unlimitedCount
        commentsCount
        commentsCountStaff
        commentsCountFlagged
        sessionsTotal
        sessionsAvailable
      }
    }
  }
`;

export const PASSES_LIST = gql`
  query Passes(
    $termId: ID
    $userId: ID
    $studentId: ID
    $classId: ID
    $category: String
    $status: [String]
    $name: String
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    list: passes(
      termId: $termId
      userId: $userId
      studentId: $studentId
      classId: $classId
      category: $category
      status: $status
      name: $name
      from: $from
      to: $to
      limit: $limit
      page: $page
    ) {
      passes {
        id
        name
        user {
          id
          email
          phone
        }
        student {
          id
          name
        }
        term {
          id
          name
        }
        category
        classes {
          id
          name
        }
        total
        method
        ref
        intent
        voucher
        discount
        refunded
        status
        created
        updated
        rosterCount
      }
      total
      page
    }
  }
`;

export const APPLICABLE_SESSIONS = gql`
  query ApplicableSessions($classId: ID!, $studentId: ID!, $status: [String]) {
    sessions: applicableSessions(
      classId: $classId
      studentId: $studentId
      status: $status
    ) {
      id
      name
      date
      start
      end
      status
    }
  }
`;

export const PASS_INFO = gql`
  query Pass($passId: ID) {
    pass(passId: $passId) {
      id
      name
      user {
        id
      }
      student {
        id
        name
      }
      term {
        id
        name
      }
      category
      classes {
        id
        name
      }
      total
      method
      ref
      intent
      voucher
      discount
      refunded
      status
      created
      updated
    }
  }
`;

export const APPLICABLE_CLASSES = gql`
  query ApplicableClasses($studentId: ID!, $termId: ID!) {
    classes: applicableClasses(studentId: $studentId, termId: $termId) {
      id
      name
      price
      taxed
      day
      from
      to
      capacity
      term {
        id
        name
        start
        end
        dropIn
        unlimited
      }
      program {
        id
        name
      }
      group {
        id
        name
        min
        max
      }
      location {
        id
        name
        suburb
      }
      preassessment
      status
      enrolmentCount
      dropinCount
      unlimitedCount
      commentsCount
      commentsCountStaff
      commentsCountFlagged
      sessionsTotal
      sessionsAvailable
    }
  }
`;

export const AWAITING_PAYMENT_LIST = gql`
  query AwaitingPaymentsList($userId: ID) {
    list: awaitingPaymentsList(userId: $userId) {
      entityId
      entityType
      title
      description
      amount
      status
    }
  }
`;

export const AWAITING_PAYMENT_INFO = gql`
  query AwaitingPaymentInfo($entityType: String!, $entityId: ID!) {
    info: awaitingPaymentInfo(entityType: $entityType, entityId: $entityId) {
      entityId
      entityType
      title
      description
      amount
      status
    }
  }
`;

export const USER_TRANSACTIONS_LIST = gql`
  query UserTransactionsList($userId: ID) {
    list: userTransactionsList(userId: $userId) {
      entityId
      entityType
      title
      description
      amount
      receipt
      status
      timestamp
    }
  }
`;

export const LIST_LOCATIONS = gql`
  query ListLocations {
    locations: listLocations {
      id
      name
      line1
      line2
      suburb
      state
      postcode
      email
      active
    }
  }
`;

export const LIST_PRODUCTS = gql`
  query ListProducts(
    $name: String
    $status: String
    $groupId: ID
    $programId: ID
  ) {
    products: listProducts(
      name: $name
      status: $status
      groupId: $groupId
      programId: $programId
    ) {
      id
      name
      thumbnail
      description
      cost
      price
      groups {
        id
        name
      }
      programs {
        id
        name
      }
      status
      created
      updated
    }
  }
`;

export const LIST_HALLS = gql`
  query ListHalls($location: ID, $status: String) {
    halls: listHalls(location: $location, status: $status) {
      id
      location {
        id
        name
      }
      name
      capacity
      price
      deposit
      days
      from
      to
      status
    }
  }
`;

export const TRANSACTION_DETAILS = gql`
  query Transaction($id: ID) {
    transaction(transactionId: $id) {
      id
      status
      type
      entity
      method
      ref
      secret
      user {
        id
        email
      }
      amount
      discount
      refunded
      deposit {
        amount
        returned
      }
      redeemed
      vouchers {
        id
        type
        status
      }
      created
      updated
      enrolment {
        id
        name
      }
      pass {
        id
        name
      }
      order {
        id
        number
      }
      training {
        id
        name
      }
      performance {
        id
        name
      }
      hire {
        id
        name
      }
    }
  }
`;

export const GET_HALL_AVAILABILITY_SLOTS = gql`
  query CheckHallAvailability($hallId: ID, $date: String, $duration: Int) {
    slots: checkHallAvailability(
      hallId: $hallId
      date: $date
      duration: $duration
    ) {
      id
      from
      to
      date
      start
      end
      duration
    }
  }
`;

export const LIST_TRANSACTIONS = gql`
  query Transactions(
    $type: String
    $userId: ID
    $entityId: ID
    $status: [String]
    $from: String
    $to: String
    $limit: Int
    $page: Int
  ) {
    transactions(
      type: $type
      userId: $userId
      entityId: $entityId
      status: $status
      from: $from
      to: $to
      limit: $limit
      page: $page
    ) {
      list: transactions {
        id
        type
        status
        method
        entity
        ref
        amount
        deposit {
          amount
          returned
        }
        discount
        refunded
        redeemed
        created
        updated
        enrolment {
          id
          name
        }
        pass {
          id
          name
        }
        order {
          id
          number
        }
        training {
          id
          name
        }
        performance {
          id
          name
        }
        hire {
          id
          name
        }
      }
      page
      total
    }
  }
`;
