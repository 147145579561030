import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import toast from "react-hot-toast";
import clsx from "clsx";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { APPLICABLE_CLASSES } from "../../utils/queries";
import { formatter } from "../../utils/helpers";

export const ClassSelector = (props) => {
  const { student, term, selection, setSelection, type, category } = props;

  const [classes, setClasses] = useState([]);
  const [options, setOptions] = useState({
    locations: [],
    programs: [],
    groups: [],
  });
  const [filter, setFilter] = useState({
    location: "All",
    program: "All",
    group: "All",
  });
  const [results, setResults] = useState([]);

  useQuery(APPLICABLE_CLASSES, {
    variables: {
      studentId: student.id,
      termId: term.id,
    },
    onCompleted: (data) => {
      setClasses(data.classes);
      if (data.classes.length > 0) {
        // set options data based on classes
        setOptions({
          locations: [...new Set(data.classes.map((c) => c.location))],
          programs: [...new Set(data.classes.map((c) => c.program))],
          groups: [...new Set(data.classes.map((c) => c.group))],
        });
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  // on filter change update filtered classes
  useEffect(() => {
    // filter classes based on filter
    let filtered = classes;
    // if term is selected, filter by term
    if (!!term) {
      filtered = filtered.filter((c) => c.term.id === term.id);
    }
    // if location is selected, filter by location
    if (filter.location && filter.location !== "All") {
      filtered = filtered.filter((c) => c.location.id === filter.location);
    }
    // if program is selected, filter by program
    if (filter.program && filter.program !== "All") {
      filtered = filtered.filter((c) => c.program.id === filter.program);
    }
    // if group is selected, filter by group
    if (filter.group && filter.group !== "All") {
      filtered = filtered.filter((c) => c.group.id === filter.group);
    }
    // update filtered classes
    setResults(filtered);
  }, [term, filter, classes]);

  return (
    <div className="relative border-b-2 pb-10 mb-4 pt-2">
      {classes.length > 0 ? (
        <div>
          {/* dropdown filters for options */}
          <div className="w-full flex flex-col">
            <div className="flex flex-col sm:flex-row gap-2">
              {/* select location */}
              <div className="basis-1/3">
                <label className="label">
                  <span className="label-text font-semibold">
                    Filter by location
                  </span>
                </label>
                <select
                  className="select select-bordered w-full bg-white"
                  value={filter.location}
                  onChange={(e) =>
                    setFilter({ ...filter, location: e.target.value })
                  }
                >
                  {/* all */}
                  <option value="All">All</option>
                  {options.locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* select program */}
              <div className="basis-1/3">
                <label className="label">
                  <span className="label-text font-semibold">
                    Filter by program
                  </span>
                </label>
                <select
                  className="select select-bordered w-full bg-white"
                  value={filter.program}
                  onChange={(e) =>
                    setFilter({ ...filter, program: e.target.value })
                  }
                >
                  <option value="All">All</option>
                  {options.programs.map((program) => (
                    <option key={program.id} value={program.id}>
                      {program.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* select group */}
              <div className="basis-1/3">
                <label className="label">
                  <span className="label-text font-semibold">
                    Filter by group
                  </span>
                </label>
                <select
                  className="select select-bordered w-full bg-white"
                  value={filter.group}
                  onChange={(e) =>
                    setFilter({ ...filter, group: e.target.value })
                  }
                >
                  <option value="All">All</option>
                  {options.groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* radio group for classes results */}
          <RadioGroup value={selection} onChange={setSelection}>
            {results.length > 0 && (
              <RadioGroup.Label className="label">
                <span className="label-text font-semibold">Select a class</span>
              </RadioGroup.Label>
            )}
            <div className="flex flex-col gap-2 w-full">
              {results.length > 0 ? (
                results.map((classInfo) => (
                  <RadioGroup.Option
                    key={classInfo.id}
                    value={classInfo}
                    className={({ active, checked }) =>
                      clsx(
                        active && "ring-2 ring-offset-2 ring-primary",
                        checked
                          ? "bg-base-200 text-primary ring-1 ring-offset-1 ring-primary"
                          : "bg-white text-gray-900",
                        "relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none"
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <div className="flex w-full items-center justify-between">
                          <div className="flex items-center gap-4">
                            <div className="text-sm">
                              <RadioGroup.Label
                                as="p"
                                className={`font-medium  ${
                                  checked ? "text-primary" : "text-gray-900"
                                }`}
                              >
                                {classInfo.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className={`inline ${
                                  checked ? "text-gray-900" : "text-gray-500"
                                }`}
                              >
                                <span>
                                  {classInfo.day}, {classInfo.from}-
                                  {classInfo.to}{" "}
                                </span>
                                <span>
                                  | {classInfo.sessionsAvailable} sessions{" "}
                                  remaining{" "}
                                </span>
                                {type === "enrolment" && (
                                  <span>
                                    | {formatter.format(classInfo.price / 100)}{" "}
                                    per session{" "}
                                  </span>
                                )}
                                {type === "pass" && category === "DROPIN" && (
                                  <span>
                                    |{" "}
                                    {formatter.format(
                                      classInfo.term.dropIn / 100
                                    )}{" "}
                                    per pass{" "}
                                  </span>
                                )}
                                {type === "pass" &&
                                  category === "UNLIMITED" && (
                                    <span>
                                      |{" "}
                                      {formatter.format(
                                        classInfo.term.unlimited / 100
                                      )}{" "}
                                      per pass
                                    </span>
                                  )}
                              </RadioGroup.Description>
                            </div>
                          </div>
                          <div className="shrink-0 fill-secondary">
                            {checked && <CheckCircleIcon className="h-5 w-5" />}
                          </div>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))
              ) : (
                <div className="flex flex-row gap-2 justify-center w-full pt-6">
                  <p className="text-gray-500">No classes found.</p>
                </div>
              )}
            </div>
          </RadioGroup>
        </div>
      ) : (
        <div className="flex justify-center p-6">
          <p>No classes available for the student</p>
        </div>
      )}
    </div>
  );
};
